import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'

import {
    Breadcrumb, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../util/get-cache-handler'
import { getTrans } from '../util/get-trans-handler'
import { getAxiosArmies } from '../util/get-axios-armies-handler'

import LocalImage from '../components/local-image'
import Share from '../components/share'
import Layout from "../components/layout"

import '../css/division-post.css'


class DivisionPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            trans: [],
            division : [],
        }
    }

    async componentDidMount() {

        let self = this
        
        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({ trans: _t })

        
        /////// Setup Data //////////
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined
        // let divisionslist = JSON.parse(localStorage.getItem('divisionslist')) || undefined

        // Get Local Cache
        if ( typeof armieslist === 'undefined' || getCache() ) {
            console.log(`load ajax`)
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('armieslist', JSON.stringify(armieslist))
            localStorage.setItem('version', +(new Date()))
        }
        
        /////// Display Data //////////
        if ( armieslist ){
            let _id = self.props.pageContext.divisionId
            let division = _.find( armieslist, {'ArmieID':_id})
            // division['faction'] = _.find( armieslist, {'ArmieID':division.ParentArmy})['Name']
            division['faction'] = (division.ParentArmy) ?
                _.find( armieslist, (o) => {
                    console.log(`o.ArmieID === division.ParentArmy (${division.ParentArmy}) = o.Name = ${o.Name}`)
                    return (o.ArmieID === division.ParentArmy ) ? o.Name : ""
                })
                : ""
            // console.log(`division = `, division)

            self.setState({ 
                division: division,
            })
        }
    }

    render() {
        let _t = this.state.trans
        const slug = this.props.pageContext.slugId
        let division = this.state.division || this.props.pageContext.division

        // DEBUG :
        // console.log(`==== Division : this.props ==== `, this.props)
        // console.log(`Division = `, division)

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {

                    const lang = i18n.language.toLowerCase()

                    const ogImg = ( division.Logo ) ? division.Logo: ''
                    const pagepath = this.props.location.pathname
                    const pagetitle = `${t('MU000')} | ${t('MU005')} | ${t('MU008')} | ${t('PT6200')} | ${t(division.Name)}`
                    // console.log(`<Share url=${pagepath} title=${pagetitle} display="row" caption={t('PT0301')}> / this.props.location.href = ${this.props.location.href}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>

                        <div id="DivisionContent" className="body-page armiespage">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                    {/* <Breadcrumb.Item href={`/${lang}/map/divisions/`}> {t('PT6100')} ></Breadcrumb.Item> */}
                                </Breadcrumb>
                            </Container>
                            
                            <main role="main" className="main">

                                <Container className="wrapper">
                                    <Row className="line-base">
                                        <Col xs={12} md={4} className="top-box text-center">
                                            {/* <LocalImage name={division.Logo.split('.')[0]} attr={{className: "img-fluid", alt:`Go Link`}}/> */}
                                        {
                                            ( ogImg ) ?
                                                <LocalImage name={ogImg.split('.')[0]} attr={{className: "img-fluid", alt:`${division.Name} Art`}}/>
                                                : null
                                        }

                                            <div className="ptitle d-block d-md-none">{t(division.Name)}</div>
                                        </Col>

                                        <Col xs={12} md={8} className="py-4">

                                            <div className="ptitle d-none d-md-block">{t(division.Name)}</div>

                                            <div className="data-table">
                                                {
                                                    ( division.faction ) ?
                                                    <Row className="line-top line-inter">
                                                        <Col className="caption" xs={4} md={3} lg={2}>{t('PT5100')}</Col>
                                                        <Col xs={8} md={9} lg={10}>
                                                            <a href={`/${lang}/map/factions/${_t[division.faction.Name].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`}>{t(division.faction.Name)}</a>
                                                        </Col>
                                                    </Row>
                                                    : null
                                                }
                                            </div>

                                            <div className="desp">{t(division.Description)}</div>
                                            
                                            <div className="morelink">
                                                <Link to={`/database/divisions#${slug}`}>{t('PT6201')} <LocalImage name={`linkgo`} attr={{className: "linkgo", alt:`Go Link`}}/> </Link>
                                            </div>

                                            <div className="d-flex justify-content-center share-side">
                                                <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')}/>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>

                            </main>

                        </div>




                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(DivisionPost)

DivisionPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
query GetDivisionPost($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
    en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`